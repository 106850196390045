import Helmet from "react-helmet";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MoolaConfiguration from "./config";
import { WalletProvider } from "./providers/wallet";
import { LendingPoolProvider } from "./providers/lending-pool";
import Landing from "./features/landing";
import NotFound from "./features/not-found";
import Layout from "./components/layout";
import FavIcon from "./assets/images/favicon.svg";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App = () => {
  return (
    <WalletProvider>
      <LendingPoolProvider config={MoolaConfiguration}>
        <Helmet>
          <link rel="icon" href={FavIcon} />
          <title>Moola MiniPay</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing config={MoolaConfiguration} />} />
            <Route
              path="landing"
              element={<Landing config={MoolaConfiguration} />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <ToastContainer />
      </LendingPoolProvider>
    </WalletProvider>
  );
};

export default App;
