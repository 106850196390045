import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import { TextBox } from "../../components/textbox";
import { ReactComponent as MoolaLogo } from "../../assets/images/logo.svg";
import { useDeposit } from "../../hooks/use-deposit";
import { useWalletBalance } from "../../hooks/use-wallet-balance";
import { useMoolaDeposited } from "../../hooks/use-moola-deposited";
import { useWithdraw } from "../../hooks/use-withdraw";
import { useMoolaReserve } from "../../hooks/use-mola-reserve";

const Landing = ({ config }) => {
  const [amount, setAmount] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState();
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const { depositedCusd } = useMoolaDeposited({
    config,
    triggerUpdate,
  });
  const {
    deposit,
    isLoading: isLoadingDeposit,
    isSuccess: isSuccessDeposit,
    transactionHash: depositTransactionHash,
  } = useDeposit({ config });
  const {
    withdraw,
    isLoading: isLoadingWithdraw,
    isSuccess: isSuccessWithdraw,
    transactionHash: withdrawTransactionHash,
  } = useWithdraw({ config, depositedCusd });
  const { cusdApy } = useMoolaReserve();
  const { balanceCusd } = useWalletBalance({
    config,
    triggerUpdate,
  });

  useEffect(() => {
    if (isSuccessDeposit) {
      setTriggerUpdate(depositTransactionHash);
    }
  }, [isSuccessDeposit, depositTransactionHash]);

  useEffect(() => {
    if (isSuccessWithdraw) {
      setTriggerUpdate(withdrawTransactionHash);
    }
  }, [isSuccessWithdraw, withdrawTransactionHash]);

  useEffect(() => {
    if (isLoadingDeposit || isLoadingWithdraw) {
      setActionsDisabled(true);
    } else {
      setActionsDisabled(false);
    }
  }, [isLoadingDeposit, isLoadingWithdraw]);

  const onClickDeposit = async () => {
    await deposit({ amount, balanceCusd });
  };

  const onClickWithdraw = async () => {
    await withdraw({ amount, depositedCusd });
  };

  return (
    <div className="landing">
      <MoolaLogo className="logo" />
      <div className="wrapper">
        <div className="wallet-balance">
          ${balanceCusd?.toFixed(2)?.toString() || 0}
        </div>
        <div className="your-earnings">
          Deposit and earn up to{" "}
          <span className="apy">
            {cusdApy ? cusdApy?.toFixed(2)?.toString() : "1.80"}%
          </span>{" "}
          APY
        </div>

        <div className="action-box-wrapper">
          <div className="action-box">
            <div className="amount-wrapper">
              <div className="amount-label">Amount </div>{" "}
              <TextBox
                value={amount}
                onChange={setAmount}
                className="amount-textbox"
              ></TextBox>{" "}
              <div className="amount-token">cUSD</div>
            </div>
            <div className="buttons-wrapper">
              <Button
                onClick={onClickDeposit}
                label="Deposit"
                className={"button"}
                disabled={actionsDisabled}
              ></Button>{" "}
              {depositedCusd?.toFixed(2) > 0 && (
                <Button
                  onClick={onClickWithdraw}
                  label="Withdraw"
                  className={"button"}
                  disabled={actionsDisabled}
                ></Button>
              )}
            </div>
          </div>
        </div>
        <div className="deposited-amount">
          Your Deposited Amount{" "}
          <span className="amount">
            ${depositedCusd?.toFixed(2).toString() || 0}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Landing;
