import { useEffect, useState } from "react";
import { useContext } from "react";
import { WalletContext } from "../../providers/wallet";

export const useMoolaReserve = () => {
  const walletContext = useContext(WalletContext);
  const { userAddress } = walletContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [cusdApy, setCusdApy] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://v2-mooapi.herokuapp.com/get/getUserReserveData?userPublicKey=${userAddress.slice(
            2
          )}&currency=cUSD`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        const cusdData = jsonData?.data?.find((el) => el.currency === "cUSD");
        if (cusdData) {
          setCusdApy(parseFloat(cusdData?.depositInterest));
          setIsSuccess(true);
        } else {
          setIsError(true);
          setError("Error retrieving apy");
        }
      } catch (error) {
        setIsError(true);
        setError("Error retrieving deposited amount");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [cusdApy, userAddress]);

  return { isLoading, isSuccess, isError, error, cusdApy };
};
