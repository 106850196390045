const Button = ({ onClick, className, disabled, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={`${className ?? ""} ${disabled ? "disabled" : ""}`}
      onClick={() => !disabled && onClick()}
    >
      {props.label}
    </button>
  );
};

export { Button };
