export const autoClose = 2500

export const TRANSACTION_FEE = 0.0055

export const MAX_UINT_256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const ERRORS = {
  WALLET_CANNOT_INITIALIZE: 'Cannot initialise wallet.',
  TRANSACTION_REJECTED: 'Transaction rejected by user.',
  UNHANDLED_ERROR: 'An unhandled error occurred.',
  AMOUNT_DEPOSIT_NOT_SET: 'Amount to deposit is not set. Please specify an amount.',
  AMOUNT_WITHDRAW_NOT_SET: 'Amount to withdraw is not set. Please specify an amount.',
  AMOUNT_MORE_THAN_BALANCE: 'Cannot deposit more than your account balance. Please specify a lower amount.',
  AMOUNT_MORE_THAN_DEPOSITED: 'Cannot withdraw more than your deposited amount.',
  AMOUNT_DEPOSIT_TRANSACTION_FEE:
    'Cannot deposit the specified amount. Your account balance must support the transaction fees. Please specify a lower amount to deposit.'
}

export const getUserFriendlyError = (error) => {
  console.log('error', error)
  let jsonError
  try {
    jsonError = JSON.stringify(error)
  } catch (error) {}

  if (
    error?.message?.includes('User denied transaction') ||
    error?.details?.includes('User rejected transaction') ||
    jsonError?.includes('User rejected transaction')
  ) {
    return ERRORS.TRANSACTION_REJECTED
  }

  return ERRORS.UNHANDLED_ERROR
}
