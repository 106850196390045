import LendingPoolAbi from "./abi/LendingPool.json";
import LendingAddressProviderAbi from "./abi/LendingPoolAddressProvider.json";
import MTokenAbi from "./abi/MToken.json";
import MoolaProtocolDataProviderAbi from "./abi/MoolaProtocolDataProvider.json";

const MOOLA_LENDING_POOL_PROVIDER_ADDRESS =
  "0xD1088091A174d33412a968Fa34Cb67131188B332";
const MOOLA_PROTOCOL_DATA_PROVIDER_ADDRESS =
  "0x43d067ed784D9DD2ffEda73775e2CC4c560103A1";
const CUSD_ADDRESS = "0x765DE816845861e75A25fCA122bb6898B8B1282a";

const MOOLA_CONFIGURATION = {
  abis: {
    LendingPool: LendingPoolAbi,
    LendingAddressProvider: LendingAddressProviderAbi,
    MToken: MTokenAbi,
    MoolaProtocolDataProvider: MoolaProtocolDataProviderAbi,
  },
  addresses: {
    MOOLA_LENDING_POOL_PROVIDER_ADDRESS: MOOLA_LENDING_POOL_PROVIDER_ADDRESS,
    MOOLA_PROTOCOL_DATA_PROVIDER_ADDRESS: MOOLA_PROTOCOL_DATA_PROVIDER_ADDRESS,
    CUSD_ADDRESS: CUSD_ADDRESS,
  },
};

export default MOOLA_CONFIGURATION;
