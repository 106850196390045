import React, { useEffect, useState } from 'react'
import { createWalletClient, custom, createPublicClient } from 'viem'
import { celo } from 'viem/chains'

const WalletContext = React.createContext()

const WalletProvider = (props) => {
  const [publicClient, setPublicClient] = useState()
  const [walletClient, setWalletClient] = useState()
  const [userAddress, setUserAddress] = useState()

  useEffect(() => {
    if (window?.ethereum && window.ethereum.isMiniPay) {
      const initializePublicClient = async () => {
        const customPublicClient = createPublicClient({
          chain: celo,
          transport: custom(window.ethereum)
        })
        setPublicClient(customPublicClient)
      }
      initializePublicClient()
    }
  }, [])

  useEffect(() => {
    const initializeWalletClient = async () => {
      const customWalletClient = createWalletClient({
        chain: celo,
        transport: custom(window.ethereum)
      })
      setWalletClient(customWalletClient)
      const [userAddress] = await customWalletClient.getAddresses()
      setUserAddress(userAddress)
    }
    initializeWalletClient()
  }, [])

  const isWalletInitialised = publicClient && walletClient && userAddress

  return (
    <>
      {isWalletInitialised ? (
        <WalletContext.Provider value={{ publicClient, walletClient, userAddress }}>
          {props.children}
        </WalletContext.Provider>
      ) : (
        'Loading...'
      )}
    </>
  )
}

export { WalletContext, WalletProvider }
