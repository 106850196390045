import { useEffect, useState } from "react";
import { formatEther } from "viem";
import { useContext } from "react";
import { WalletContext } from "../../providers/wallet";
import { ERRORS } from "../../helpers";

export const useMoolaDeposited = ({ config, triggerUpdate }) => {
  const walletContext = useContext(WalletContext);
  const { publicClient, walletClient, userAddress } = walletContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [depositedCusd, setDepositedCusd] = useState(0);

  if (!(publicClient && walletClient && userAddress)) {
    throw new Error(ERRORS.WALLET_CANNOT_INITIALIZE);
  }

  useEffect(() => {
    const initializeDepositedAmount = async () => {
      setIsLoading(true);
      try {
        const data = await publicClient.readContract({
          address: config.addresses.MOOLA_PROTOCOL_DATA_PROVIDER_ADDRESS,
          abi: config.abis.MoolaProtocolDataProvider,
          functionName: "getUserReserveData",
          args: [config.addresses.CUSD_ADDRESS, userAddress],
        });
        if (data) {
          setDepositedCusd(parseFloat(formatEther(data[0])));
          setIsSuccess(true);
        } else {
          if (JSON.stringify(data) === `"0"`) {
            setDepositedCusd(0);
          } else {
            setError("Error retrieving deposited amount");
          }
        }
      } catch (error) {
        setIsError(true);
        setError("Error retrieving deposited amount");
      } finally {
        setIsLoading(false);
      }
    };
    initializeDepositedAmount();
  }, [
    publicClient,
    userAddress,
    config.abis.MToken,
    config.addresses.CUSD_ADDRESS,
    config.addresses.MOOLA_PROTOCOL_DATA_PROVIDER_ADDRESS,
    config.abis.MoolaProtocolDataProvider,
    triggerUpdate,
  ]);

  return { isLoading, isSuccess, isError, error, depositedCusd };
};
