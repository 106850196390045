import { useEffect, useState } from "react";
import { useContext } from "react";
import { WalletContext } from "../../providers/wallet";
import { ERRORS } from "../../helpers";

export const useLendingPool = ({ config }) => {
  const walletContext = useContext(WalletContext);
  const { publicClient } = walletContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [lendingPool, setLendingPool] = useState();

  if (!publicClient) {
    throw new Error(ERRORS.WALLET_CANNOT_INITIALIZE);
  }

  useEffect(() => {
    const initializeLendingPool = async () => {
      setIsLoading(true);
      try {
        const data = await publicClient.readContract({
          address: config.addresses.MOOLA_LENDING_POOL_PROVIDER_ADDRESS,
          abi: config.abis.LendingAddressProvider,
          functionName: "getLendingPool",
        });
        if (data) {
          setLendingPool(data);
          setIsSuccess(true);
        } else {
          setError("Error retrieving lending pool address");
        }
      } catch (error) {
        setIsError(true);
        setError("Error retrieving lending pool address");
      } finally {
        setIsLoading(false);
      }
    };
    initializeLendingPool();
  }, [
    publicClient,
    config.addresses.MOOLA_LENDING_POOL_PROVIDER_ADDRESS,
    config.abis.LendingAddressProvider,
  ]);

  return { isLoading, isSuccess, isError, error, lendingPool };
};
