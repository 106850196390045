import React from 'react'
import { useLendingPool } from '../../hooks/use-lending-pool'

const LendingPoolContext = React.createContext()

const LendingPoolProvider = (props) => {
  const { config } = props
  const { lendingPool, isLoading, isSuccess, isError, error } = useLendingPool({
    config
  })
  return (
    <>
      {isLoading && <div>Connecting to Moola Lending Pool</div>}
      {isSuccess && <LendingPoolContext.Provider value={{ lendingPool }}>{props.children}</LendingPoolContext.Provider>}
      {isError && <div>{error}</div>}
    </>
  )
}

export { LendingPoolContext, LendingPoolProvider }
