import { useState } from "react";
import { parseEther, formatEther } from "viem";
import { useContext } from "react";
import { toast } from "react-toastify";
import { WalletContext } from "../../providers/wallet";
import {
  sleep,
  getUserFriendlyError,
  MAX_UINT_256,
  ERRORS,
  autoClose,
  TRANSACTION_FEE,
} from "../../helpers";
import { LendingPoolContext } from "../../providers/lending-pool";

export const useDeposit = ({ config }) => {
  const walletContext = useContext(WalletContext);
  const lendingPoolContext = useContext(LendingPoolContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [transactionHash, setTransactionHash] = useState();
  const { publicClient, walletClient, userAddress } = walletContext;

  if (!(publicClient && walletClient && userAddress)) {
    throw new Error(ERRORS.WALLET_CANNOT_INITIALIZE);
  }

  const isValid = ({ amount, balanceCusd }) => {
    if (amount > balanceCusd) {
      toast(ERRORS.AMOUNT_MORE_THAN_BALANCE, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: autoClose,
        closeButton: true,
      });
      return false;
    }

    if (balanceCusd - amount < TRANSACTION_FEE) {
      toast(ERRORS.AMOUNT_DEPOSIT_TRANSACTION_FEE, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        closeButton: true,
      });
      return false;
    }

    return true;
  };

  const deposit = async ({ amount, balanceCusd }) => {
    let toastId;
    try {
      setIsLoading(true);
      if (amount) {
        try {
          const depositIsValid = isValid({ amount, balanceCusd });
          if (depositIsValid) {
            toastId = toast(`Depositing ${amount?.toString()} cUSD...`, {
              type: toast.TYPE.INFO,
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: false,
              closeButton: false,
            });
            await sleep(1500);

            const data = await publicClient.readContract({
              address: config.addresses.CUSD_ADDRESS,
              abi: config.abis.MToken,
              functionName: "allowance",
              args: [userAddress, lendingPoolContext.lendingPool],
            });

            if (parseFloat(formatEther(data)) < parseFloat(amount)) {
              const { request: approveRequest } =
                await publicClient.simulateContract({
                  account: userAddress,
                  address: config.addresses.CUSD_ADDRESS,
                  abi: config.abis.MToken,
                  functionName: "approve",
                  args: [lendingPoolContext.lendingPool, MAX_UINT_256],
                });

              if (approveRequest) {
                const transactionHash = await walletClient.writeContract(
                  approveRequest
                );
                const approveTransaction =
                  await publicClient.waitForTransactionReceipt({
                    hash: transactionHash,
                  });

                if (!approveTransaction?.transactionHash) {
                  setIsError(true);
                  setIsError("An error occured for approve transaction");
                  toast.update(toastId, {
                    render: `An error occured for approve transaction`,
                    type: toast.TYPE.ERROR,
                    autoClose: autoClose,
                    closeButton: true,
                  });
                }
              } else {
                setIsError(true);
                setError("An error occured for approve transation");
                toast.update(toastId, {
                  render: `An error occured for approve transation`,
                  type: toast.TYPE.ERROR,
                  autoClose: autoClose,
                  closeButton: true,
                });
              }
            }

            const { request: depositRequest } =
              await publicClient.simulateContract({
                account: userAddress,
                address: lendingPoolContext.lendingPool,
                abi: config.abis.LendingPool,
                functionName: "deposit",
                args: [
                  config.addresses.CUSD_ADDRESS,
                  parseEther(amount),
                  userAddress,
                  0,
                ],
              });

            if (depositRequest) {
              const depositTransactionHash = await walletClient.writeContract(
                depositRequest
              );
              const depositTransaction =
                await publicClient.waitForTransactionReceipt({
                  hash: depositTransactionHash,
                });
              if (depositTransaction?.transactionHash) {
                setTransactionHash(depositTransaction?.transactionHash);
                setIsSuccess(true);
                toast.update(toastId, {
                  render: `Deposited ${amount} cUSD`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: autoClose,
                  closeButton: true,
                });
              }
            } else {
              setIsError(true);
              setError("An error occured for deposit transation");
              toast.update(toastId, {
                render: `An error occured for deposit transation`,
                type: toast.TYPE.ERROR,
                autoClose: autoClose,
                closeButton: true,
              });
            }
          }
        } catch (error) {
          setIsError(true);
          setError(getUserFriendlyError(error));
          toast.update(toastId, {
            render: getUserFriendlyError(error),
            type: toast.TYPE.ERROR,
            autoClose: autoClose,
            closeButton: true,
          });
        }
      } else {
        setIsError(true);
        setError(ERRORS.AMOUNT_WITHDRAW_NOT_SET);
        toastId = toast(ERRORS.AMOUNT_WITHDRAW_NOT_SET, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: autoClose,
          closeButton: true,
        });
      }
    } catch (error) {
      setIsError(true);
      setError(getUserFriendlyError(error));
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { deposit, isLoading, isSuccess, isError, error, transactionHash };
};
