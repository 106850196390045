const TextBox = ({ amount, className, onChange, ...props }) => {
  return (
    <input
      type="number"
      placeholder="0.00"
      className={className ?? ''}
      value={amount}
      {...props}
      onChange={(event) => {
        const { value: newValue } = event.target
        let newValueToTest = newValue
        if (newValue.startsWith('.')) {
          newValueToTest = `0${newValue}`
        }
        const regex = /^(?:[1-9]\d*|0|(?:\d+\.\d{1,2}))$/
        if (newValueToTest === '' || regex.test(newValueToTest)) {
          onChange(newValueToTest)
        }
      }}
    ></input>
  )
}

export { TextBox }
