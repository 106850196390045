import { useState } from "react";
import { parseEther } from "viem";
import { useContext } from "react";
import { toast } from "react-toastify";
import { WalletContext } from "../../providers/wallet";
import { LendingPoolContext } from "../../providers/lending-pool";
import { sleep, getUserFriendlyError, ERRORS, autoClose } from "../../helpers";

export const useWithdraw = ({ config }) => {
  const walletContext = useContext(WalletContext);
  const lendingPoolContext = useContext(LendingPoolContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [transactionHash, setTransactionHash] = useState();
  const { publicClient, walletClient, userAddress } = walletContext;

  if (!(publicClient && walletClient && userAddress)) {
    throw new Error(ERRORS.WALLET_CANNOT_INITIALIZE);
  }

  const isValid = ({ amount, depositedCusd }) => {
    if (amount > depositedCusd) {
      toast(ERRORS.AMOUNT_MORE_THAN_DEPOSITED, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: autoClose,
        closeButton: true,
      });
      return false;
    }
    return true;
  };

  const withdraw = async ({ amount, depositedCusd }) => {
    let id;
    try {
      setIsLoading(true);
      if (amount) {
        try {
          const withdrawIsValid = isValid({ amount, depositedCusd });
          if (withdrawIsValid) {
            id = toast(`Withdrawing ${amount?.toString()} cUSD...`, {
              type: toast.TYPE.INFO,
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: false,
              closeButton: false,
            });

            await sleep(1500);

            const { request: withdrawRequest } =
              await publicClient.simulateContract({
                account: userAddress,
                address: lendingPoolContext.lendingPool,
                abi: config.abis.LendingPool,
                functionName: "withdraw",
                args: [
                  config.addresses.CUSD_ADDRESS,
                  parseEther(amount.toString()),
                  userAddress,
                ],
              });

            if (withdrawRequest) {
              const transactionHash = await walletClient.writeContract(
                withdrawRequest
              );
              const transaction = await publicClient.waitForTransactionReceipt({
                hash: transactionHash,
              });
              if (transaction?.transactionHash) {
                setTransactionHash(transaction?.transactionHash);
                setIsSuccess(true);
                toast.update(id, {
                  render: `Withdrew ${amount} cUSD`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: autoClose,
                  closeButton: true,
                });
              }
            } else {
              setIsError(true);
              setIsSuccess(false);
              setTransactionHash(undefined);
              setError("An error occured for withdraw transation");
              toast.update(id, {
                render: `An error occured for withdraw transation`,
                autoClose: autoClose,
                type: toast.TYPE.ERROR,
                closeButton: true,
              });
            }
          }
        } catch (error) {
          setIsError(true);
          setError(getUserFriendlyError(error));
          setIsSuccess(false);
          toast.update(id, {
            render: getUserFriendlyError(error),
            type: toast.TYPE.ERROR,
            autoClose: autoClose,
            closeButton: true,
          });
        }
      } else {
        setIsError(true);
        setError(ERRORS.AMOUNT_WITHDRAW_NOT_SET);
        setIsSuccess("false");
        id = toast(ERRORS.AMOUNT_WITHDRAW_NOT_SET, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: autoClose,
          closeButton: true,
        });
      }
    } catch (error) {
      setIsError(true);
      setError(getUserFriendlyError(error));
      setIsSuccess(false);
      id = toast(getUserFriendlyError(error), {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: autoClose,
        closeButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { withdraw, isLoading, isSuccess, isError, error, transactionHash };
};
